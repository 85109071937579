<style lang="scss">
  .vuecal{
    box-shadow:none;
  }

  .vuecal__bg{
    border-radius: 0 0 4px 4px;
    border-bottom:1px solid #EFEFEF;
  }

  .vuecal__weekdays-headings{
    height:60px;
    color:#4A4A4A;
    padding-top:10px;
    border-top: 1px solid #EEEEEE;
    border-left: 1px solid #EEEEEE;
    border-right: 1px solid #EEEEEE;
    border-radius: 4px 4px 0 0;
    padding-right:0!important;
  }

  .vuecal__weekdays-headings, .vuecal__bg{
    background:#fff;
  }

  .vuecal--month-view .vuecal__cell:before {
    border:1px solid #EFEFEF;
  }

  .vuecal--month-view .vuecal__cell:nth-last-child(-n+7) {
    &:before{
      border-bottom:none;
    }
  }

  .vuecal--month-view .vuecal__no-event {display: none;}

  .vuecal--month-view .vuecal__cell-content {
    justify-content: flex-start;
    height: 100%;
    cursor:default;
    align-items: flex-end;
  }

  .vuecal--month-view .vuecal__cell-date {
    padding: 8px;
    color:#737A9E;
    font-size:12px;
  }

  .vuecal__event{
    cursor:pointer;
    margin:0 auto;
    background:none;
    padding:0;
    width: 90%;
    box-shadow:none!important;
    &:focus{
      box-shadow:none!important;
    }

    .vuecal__event-title{
      text-align:center;
      border-radius:10px;
      padding:5px;
      margin-bottom:5px;
      font-size:12px;


    }

    &.project{
      .vuecal__event-title{
        background-color: #E8EFFF;
        color:#0942BC;

        &:hover{
          background:#D1DEFD
        }
      }
    }
    &.task{
      .vuecal__event-title{
        background-color: #FAB413;
        color:#fff;

        &:hover{
          background:#E9A300
        }
      }
    }
  }

  .vuecal__cell-events-count{
    height: 20px;
    line-height: 20px;
    width: 20px;
    font-size: 12px;
    padding: 0;
    text-align: center;
    background:#333;
    border-radius: 50%;
  }

  .vuecal__cells.month-view .vuecal__cell--selected, .vuecal__cell--current{
    background:none;
  }

  .vuecal__cells.month-view .vuecal__cell--today{
    background:rgba(105,145,255, 0.05)
  }

  .vuecal__cell--selected{
    background:rgba(105,145,255, 0.05)
  }

  .vuecal--year-view .vuecal__cell-content{
    justify-content: start;
    padding:15px;
  }

  .calendar-arrow-left{
    padding:4px 5px!important;
    svg{
      margin-top:2px;
    }
    &:hover{
      svg{
        g{
          stroke:#fff;
        }
      }
    }
  }

  .calendar-arrow-right{
    padding:4px 5px!important;
    svg{
      margin-top:2px;
      transform: rotateY(180deg);
    }
    &:hover{
      svg{
        g{
          stroke:#fff;
        }
      }
    }
  }

  .toggle-container{
    background-color: #FFFFFF;
    border: 1px solid #E3E3E3;
    border-radius: 4px;
    color:#4A4A4A;
    font-size:10px;
    cursor:pointer;

    .checkmark{
      padding:5px 8px 2px 8px;
      border-right:  1px solid #E3E3E3;
      svg{
        width:12px;
        opacity:0;
        transition: 0.3s;
      }
    }

    .type{
      background:none;
      padding:3px 10px 3px 10px;
      font-weight:bold;
    }

    &.active{
      .checkmark{
        svg{
          opacity:1;
        }
      }
    }

    &:hover{
      background: #698fff;
      color:#fff;
      .checkmark{
        border-right:  1px solid #fff;
        svg{
          g, path{
            fill:#fff;
          }
        }
      }
    }

  }

  #calendar-detail{
    position:absolute;
    outline:0;
    background:#fff;
    padding:15px;
    z-index: 100;
    left:-9999px;
    top:-9999px;
    border-radius:4px;
    border:1px solid #efefef;
    max-width:240px;
    font-size:12px;
    width:auto;
    box-shadow: 0 0 8px 0 rgba(0, 0, 0, 0.1);
  }
</style>
<template>
  <div class="mt-8 pb-32 mx-auto max-w-2xl">
    <vue-headful
      title="Calendar | Octoa"
    />

    <div
      id="calendar-detail"
      tabindex="-1"
      @focusout="focusOutCalendarDetail"
    >
      <div class="flex">
        <div class="w-11/12 font-bold break-words">
          <v-checkbox
            v-show="eventDetailType == 'task'"
            id="eventDetailIsCompleted"
            v-model="eventDetailIsCompleted"
            :checked="eventDetailIsCompleted"
            class="mb-1"
            @click="markTaskAsCompleted()"
          />
          {{ eventDetailName }}
        </div>
        <div
          class="cursor-pointer w-8 text-right"
          @click="closeCalendarDetail"
        >
          <img
            src="@/assets/img/icons/close.svg"
            width="10"
          >
        </div>
      </div>
      <div
        class="date mt-2"
        :class="[eventDetailType == 'task' ? 'text-orange' : 'text-grey-semi-light']"
      >
        {{ eventDetailDate }}
      </div>

      <div
        v-if="eventDetailType == 'project'"
        class="mt-4"
      >
        Phase: {{ eventDetailState | toUpperCaseFormat }}
        <a
          class="cursor-pointer green underline mt-1 block"
          :href="/projects/ + eventDetailId"
        >
          Go to project
        </a>
      </div>
      <div
        v-if="eventDetailType == 'task' && eventDetailProject !== null"
        class="mt-4"
      >
        <div>Project: {{ eventDetailProject['name'] }}</div>
        <a
          class="cursor-pointer green underline mt-1 block"
          :href="/projects/ + eventDetailProject['id']"
        >
          Go to project
        </a>
      </div>
    </div>

    <div class="px-4">
      <div class="flex justify-between items-center mb-3">
        <div class="w-1/3">
          <h1 class="text-black text-xl md:text-3xl">
            Calendar
          </h1>
        </div>
        <div class="w-2/3">
          <div class="flex flex-wrap justify-end">
            <div class="inline-block">
              <div
                class="toggle-container flex items-center "
                :class="[viewProjects ? 'active' : '']"
                @click="toggleViewCalendar('projects')"
              >
                <div class="check">
                  <div class="checkmark">
                    <svg
                      width="17px"
                      height="14px"
                      viewBox="0 0 17 14"
                      version="1.1"
                      xmlns="http://www.w3.org/2000/svg"
                      xmlns:xlink="http://www.w3.org/1999/xlink"
                    >
                      <g
                        id="06---Flow"
                        stroke="none"
                        stroke-width="1"
                        fill="none"
                        fill-rule="evenodd"
                      >
                        <g
                          id="2.0---Try-for-free"
                          transform="translate(-183.000000, -413.000000)"
                          fill="#0942BC"
                        >
                          <path
                            id="Path"
                            d="M199.892398,415.16073 L197.790484,413.107706 C197.719865,413.038752 197.623978,413 197.523981,413 C197.423984,413 197.328096,413.038752 197.257477,413.107706 L188.380052,421.759735 C188.309433,421.828688 188.213545,421.867441 188.113549,421.867441 C188.013552,421.867441 187.917664,421.828688 187.847045,421.759735 L185.745131,419.706711 C185.674512,419.637757 185.578624,419.599005 185.478627,419.599005 C185.378631,419.599005 185.282743,419.637757 185.212124,419.706711 L183.11021,421.759735 C182.963263,421.902896 182.963263,422.134961 183.11021,422.278123 L187.847045,426.892294 C187.917664,426.961248 188.013552,427 188.113549,427 C188.213545,427 188.309433,426.961248 188.380052,426.892294 L199.892398,415.673986 C200.035867,415.531431 200.035867,415.303284 199.892398,415.16073 Z"
                          />
                        </g>
                      </g>
                    </svg>
                  </div>
                </div>
                <div class="type">
                  PROJECTS
                </div>
              </div>
            </div>
            <div class="ml-2 inline-block">
              <div
                class="toggle-container flex items-center "
                :class="[viewTasks ? 'active' : '']"
                @click="toggleViewCalendar('tasks')"
              >
                <div class="check">
                  <div class="checkmark">
                    <svg
                      width="17px"
                      height="14px"
                      viewBox="0 0 17 14"
                      version="1.1"
                      xmlns="http://www.w3.org/2000/svg"
                      xmlns:xlink="http://www.w3.org/1999/xlink"
                    >
                      <g
                        id="06---Flow"
                        stroke="none"
                        stroke-width="1"
                        fill="none"
                        fill-rule="evenodd"
                      >
                        <g
                          id="2.0---Try-for-free"
                          transform="translate(-183.000000, -413.000000)"
                          fill="#0942BC"
                        >
                          <path
                            id="Path"
                            d="M199.892398,415.16073 L197.790484,413.107706 C197.719865,413.038752 197.623978,413 197.523981,413 C197.423984,413 197.328096,413.038752 197.257477,413.107706 L188.380052,421.759735 C188.309433,421.828688 188.213545,421.867441 188.113549,421.867441 C188.013552,421.867441 187.917664,421.828688 187.847045,421.759735 L185.745131,419.706711 C185.674512,419.637757 185.578624,419.599005 185.478627,419.599005 C185.378631,419.599005 185.282743,419.637757 185.212124,419.706711 L183.11021,421.759735 C182.963263,421.902896 182.963263,422.134961 183.11021,422.278123 L187.847045,426.892294 C187.917664,426.961248 188.013552,427 188.113549,427 C188.213545,427 188.309433,426.961248 188.380052,426.892294 L199.892398,415.673986 C200.035867,415.531431 200.035867,415.303284 199.892398,415.16073 Z"
                          />
                        </g>
                      </g>
                    </svg>
                  </div>
                </div>
                <div class="type">
                  TASKS
                </div>
              </div>
            </div>
            <div class="sm:ml-2 mt-2 sm:mt-0 inline-block">
              <div
                class="toggle-container flex items-center "
                :class="[viewBooked ? 'active' : '']"
                @click="toggleViewCalendar('booked')"
              >
                <div class="check">
                  <div class="checkmark">
                    <svg
                      width="17px"
                      height="14px"
                      viewBox="0 0 17 14"
                      version="1.1"
                      xmlns="http://www.w3.org/2000/svg"
                      xmlns:xlink="http://www.w3.org/1999/xlink"
                    >
                      <g
                        id="06---Flow"
                        stroke="none"
                        stroke-width="1"
                        fill="none"
                        fill-rule="evenodd"
                      >
                        <g
                          id="2.0---Try-for-free"
                          transform="translate(-183.000000, -413.000000)"
                          fill="#0942BC"
                        >
                          <path
                            id="Path"
                            d="M199.892398,415.16073 L197.790484,413.107706 C197.719865,413.038752 197.623978,413 197.523981,413 C197.423984,413 197.328096,413.038752 197.257477,413.107706 L188.380052,421.759735 C188.309433,421.828688 188.213545,421.867441 188.113549,421.867441 C188.013552,421.867441 187.917664,421.828688 187.847045,421.759735 L185.745131,419.706711 C185.674512,419.637757 185.578624,419.599005 185.478627,419.599005 C185.378631,419.599005 185.282743,419.637757 185.212124,419.706711 L183.11021,421.759735 C182.963263,421.902896 182.963263,422.134961 183.11021,422.278123 L187.847045,426.892294 C187.917664,426.961248 188.013552,427 188.113549,427 C188.213545,427 188.309433,426.961248 188.380052,426.892294 L199.892398,415.673986 C200.035867,415.531431 200.035867,415.303284 199.892398,415.16073 Z"
                          />
                        </g>
                      </g>
                    </svg>
                  </div>
                </div>
                <div class="type">
                  VIEW BOOKED ONLY
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div class="flex flex-wrap justify-between mt-5 md:mt-0 mb-6 items-center">
        <div class="w-1/2 mb-5 md:mb-0 md:w-1/3  text-center md:text-left">
          <button
            class="button small"
            :class="[activeView == 'month' ? 'active' : '']"
            @click="activeView = 'month'"
          >
            MONTH
          </button>
          <button
            class="button small ml-2"
            :class="[activeView == 'year' ? 'active' : '']"
            @click="activeView = 'year'"
          >
            YEAR
          </button>
        </div>
        <div class="w-1/2 mt-5 md:mt-0 md:w-1/3 items-center text-center">
          <span
            class="text-base lg:text-lg text-grey-darker"
            v-html="calendarTitle"
          />
        </div>
        <div class="w-1/2 md:w-1/3 flex justify-center md:justify-end">
          <button
            class="button calendar-arrow-left small"
            @click="$refs.vuecal.previous()"
          >
            <svg
              width="9px"
              height="9px"
              viewBox="0 0 7 10"
              version="1.1"
              xmlns="http://www.w3.org/2000/svg"
              xmlns:xlink="http://www.w3.org/1999/xlink"
            >
              <g
                id="04---Desktop-Pages"
                stroke="none"
                stroke-width="1"
                fill="none"
                fill-rule="evenodd"
                stroke-linecap="round"
                stroke-linejoin="round"
              >
                <g
                  id="5.0---Project-Overview"
                  transform="translate(-1244.000000, -825.000000)"
                  stroke="#000000"
                  stroke-width="1.5"
                >
                  <g
                    id="arrow-right-1-copy"
                    transform="translate(1247.000000, 830.000000) scale(-1, 1) translate(-1247.000000, -830.000000) translate(1245.000000, 826.000000)"
                  >
                    <path
                      id="Path"
                      d="M0.166666667,0.25 L3.74,3.82333333 C3.78689851,3.8701651 3.81325144,3.93372267 3.81325144,4 C3.81325144,4.06627733 3.78689851,4.1298349 3.74,4.17666667 L0.166666667,7.75"
                    />
                  </g>
                </g>
              </g>
            </svg>
          </button>
          <button
            class="button small mx-2"
            @click="selectedDate = new Date()"
          >
            TODAY
          </button>
          <button
            class="button calendar-arrow-right small"
            @click="$refs.vuecal.next()"
          >
            <svg
              width="9px"
              height="9px"
              viewBox="0 0 7 10"
              version="1.1"
              xmlns="http://www.w3.org/2000/svg"
              xmlns:xlink="http://www.w3.org/1999/xlink"
            >
              <g
                id="04---Desktop-Pages"
                stroke="none"
                stroke-width="1"
                fill="none"
                fill-rule="evenodd"
                stroke-linecap="round"
                stroke-linejoin="round"
              >
                <g
                  id="5.0---Project-Overview"
                  transform="translate(-1244.000000, -825.000000)"
                  stroke="#000000"
                  stroke-width="1.5"
                >
                  <g
                    id="arrow-right-1-copy"
                    transform="translate(1247.000000, 830.000000) scale(-1, 1) translate(-1247.000000, -830.000000) translate(1245.000000, 826.000000)"
                  >
                    <path
                      id="Path"
                      d="M0.166666667,0.25 L3.74,3.82333333 C3.78689851,3.8701651 3.81325144,3.93372267 3.81325144,4 C3.81325144,4.06627733 3.78689851,4.1298349 3.74,4.17666667 L0.166666667,7.75"
                    />
                  </g>
                </g>
              </g>
            </svg>
          </button>
        </div>
      </div>
      <vue-cal
        ref="vuecal"
        :disable-views="['years', 'day', 'week']"
        hide-view-selector
        hide-title-bar
        click-to-navigate
        events-count-on-year-view
        :active-view.sync="activeView"
        events-on-month-view="short"
        style="height: 620px"
        :selected-date="selectedDate"
        :events="events"
        :on-event-click="onEventClick"
        @ready="calendarViewChange($event)"
        @view-change="calendarViewChange($event)"
      >
        <template v-slot:event="{ event, view }">
          <div
            v-show="(viewTasks && event.class == 'task') "
            :ref="`task-${event.content}`"
            class="vuecal__event-title"
            @click="onEventClick(event, $event)"
          >
            {{ event.title }}
          </div>
          <div
            v-show="(viewProjects && event.class == 'project') "
            class="vuecal__event-title"
            @click="onEventClick(event, $event)"
          >
            {{ event.title }}
          </div>
        </template>

        <template v-slot:cell-content="{ cell, view, events }">
          <div v-if="['year'].includes(view.id)">
            <div class="mb-4 text-left">
              {{ cell.content }}
            </div>

            <div class="flex flex-wrap -mx-2">
              <div
                v-if="countTaskEvent[cell.formattedDate] > 0"
                v-show="viewTasks"
                class="w-full md:w-1/2 mb-2"
              >
                <div class="vuecal--short-events">
                  <div>
                    <div class="vuecal__event task">
                      <div
                        class="vuecal__event-title"
                        @click="activeView = 'month'"
                      >
                        <span v-if="countTaskEvent[cell.formattedDate] < 2"> {{ countTaskEvent[cell.formattedDate] }} task</span>
                        <span v-else> {{ countTaskEvent[cell.formattedDate] }} tasks</span>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <div
                v-for="event in events"
                v-show="(viewProjects && event.class == 'project')"
                class="w-full md:w-1/2 mb-2"
              >
                <div class="vuecal--short-events">
                  <div>
                    <div
                      class="vuecal__event"
                      :class="event.class"
                    >
                      <div
                        class="vuecal__event-title"
                        @click="onEventClick(event, $event)"
                      >
                        {{ event.title }}
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </template>
      </vue-cal>
    </div>
  </div>
</template>

<script>
import { logException } from '@/helpers'
import { createCancelSource } from '@/http'
import auth from '@/auth'
import VueCal from 'vue-cal'
import 'vue-cal/dist/vuecal.css'

let cancelSource

export default {
  components: { VueCal },
  data(){
    return {
      loading: true,
      withProjects: 0,
      withoutProjects: 0,
      totalContacts: 0,
      contactName: '',
      user: auth.user(),
      viewProjects: true,
      viewTasks: true,
      viewBooked: false,
      relatedProjects: '',
      activeView: 'month',
      selectedDate: new Date(),
      calendarTitle: '',
      events: [],
      countTaskEvent: [],
      eventDetailType: 'project',
      eventDetailDate: '',
      eventDetailPhase: '',
      eventDetailState: '',
      eventDetailName: '',
      eventDetailIsCompleted: false,
      eventDetailId: 0,
      eventDetailProject: null,
    }
  },
  async mounted() {
    let cookie = document.cookie.split(';').find((item) => item.trim().startsWith('calendarView'))
    if(cookie){
      let cookieValue = cookie.split('=')[1]
      let cookieObj = JSON.parse(cookieValue)
      this.viewProjects = cookieObj.viewProjects
      this.viewTasks = cookieObj.viewTasks
      this.viewBooked = cookieObj.viewBooked
    }

    await this.fetchData()

    window.addEventListener('resize', function(event){
      let calendarTooltip = document.getElementById('calendar-detail')
      calendarTooltip.style.top = '-9999px'
      calendarTooltip.style.left = '-9999px'
    })

  },
  methods: {
    focusOutCalendarDetail(eventId) {
      if(typeof eventId !== 'object' && eventId !== null){
        // this.$router.push({ name: 'ProjectDetail',  params: {id: eventId } })
      } else {
        if (!eventId.relatedTarget) {
          this.closeCalendarDetail()
        }
      }

    },
    async markTaskAsCompleted(){
      try {
        let ele = this.$refs['task-'+this.eventDetailId]
        let isCompleted = 1

        if(ele.style.textDecoration == 'line-through'){
          isCompleted = 0
          ele.style.setProperty('text-decoration', '')
        } else {
          isCompleted = 1
          ele.style.setProperty('text-decoration', 'line-through')
        }

        const { data } = await this.$api.get('tasks').complete(this.eventDetailId, {is_completed: isCompleted})

        for (const [key, arrayValue] of Object.entries(this.events)) {
          if(arrayValue.content == this.eventDetailId && arrayValue.class == 'task'){
            arrayValue.is_completed = isCompleted
          }
        }

      } catch(e){
        logException(e)
      }
    },
    toggleViewCalendar(viewType){
      this.closeCalendarDetail()
      if(viewType == 'projects'){
        if(this.viewProjects){
          this.viewProjects = false
        } else {
          this.viewProjects = true
        }
      }
      if(viewType == 'tasks'){
        if(this.viewTasks){
          this.viewTasks = false
        } else {
          this.viewTasks = true
        }
      }
      if(viewType == 'booked'){
        if(this.viewBooked){
          this.viewBooked = false
        } else {
          this.viewBooked = true
        }
        this.fetchData()
      }

      // save to cookie
      let calendarView = {
        viewProjects: this.viewProjects,
        viewTasks: this.viewTasks,
        viewBooked: this.viewBooked,
      }

      document.cookie = "calendarView=" + JSON.stringify(calendarView) + "; path=/";
    },
    customEventsCount: events => {
      return events ? events.filter(e => e.class === 'leisure').length : 0
    },
    viewEvent(event){
      if(event.class == 'project'){
        this.$router.push({ name: 'ProjectDetail',  params: {id: event.content } })
      }
      if(event.class == 'task'){
        this.$router.push({ name: 'Tasks' })
      }
    },
    closeCalendarDetail(){
      let calendarTooltip = document.getElementById('calendar-detail')
      calendarTooltip.style.top = '-9999px'
      calendarTooltip.style.left = '-9999px'
    },
    onEventClick (event, e) {
      this.eventDetailIsCompleted = false
      this.isCalendarDetailOpen = true

      let calendarTooltip = document.getElementById('calendar-detail')
      calendarTooltip.style.top = '0px'
      calendarTooltip.style.left = '0px'

      const eleRect = e.target.getBoundingClientRect()
      const targetRect = calendarTooltip.getBoundingClientRect()

      // Calculate the top and left positions
      const top = (eleRect.top - targetRect.top)+35
      const left = (eleRect.left - targetRect.left)+10

      calendarTooltip.style.top = top+'px'
      calendarTooltip.style.left = left+'px'

      this.eventDetailProject = null
      this.eventDetailType = event.class
      this.eventDetailDate = event.date
      this.eventDetailName = event.title
      this.eventDetailId = event.content
      if(event.class == 'project'){
        this.eventDetailPhase = event.phase
        this.eventDetailState = event.state
      } else {
        this.eventDetailProject = event.project
        this.eventDetailIsCompleted = event.is_completed
      }

      this.$log.debug('Opening Events: ', event)

      calendarTooltip.focus()
      // if(event.class == 'project'){
      //   this.$router.push({ name: 'ProjectDetail',  params: {id: event.content } })
      // }
      // if(event.class == 'task'){
      //   this.$router.push({ name: 'Tasks' })
      // }

      //e.stopPropagation()
    },
    calendarViewChange(payload){
      this.closeCalendarDetail()
      var title = this.$refs.vuecal.viewTitle
      if(this.activeView == 'month'){
        var result = ''
        title = title.split(' ')
        for(var i =0; i < title.length; i++){
          if(i == 0){
            result += '<span class="font-bold">'+title[i]+'</span> '
          } else {
            result += title[i]
          }
        }
      } else {
        result = title
      }

      this.calendarTitle = result
    },
    async fetchData() {

      this.countTaskEvent = []
      try {
        const { data } = await this.$api.get('calendar').view(this.user.me.company.id, this.viewBooked)
        this.events = data.results

        this.events.forEach(event => (event.class == 'task') ? this.countTaskEvent[new Date(event.start).format('YYYY-MM')] = 0 : '' )
        for (const [key, arrayValue] of Object.entries(this.events)) {
          let date = new Date(arrayValue.start).format('YYYY-MM')
          date += '-01'
          if(arrayValue.class == 'task'){

            if(!this.countTaskEvent[date]){
              this.countTaskEvent[date] = 0
            }
            this.countTaskEvent[date] += 1
          }
        }
      } catch(e){
        logException(e)
      }
    }
  }
}
</script>
